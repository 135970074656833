<template>
  <div class="space-y-1 flex-grow overflow-y-auto text-gray-500 italic mb-2">
    {{ message }}
    <a
      v-if="showClearFilter"
      href="#"
      @click.prevent="$emit('clear-filter')"
      class="text-blue-500 hover:underline ml-1"
    >
      [Clear filter]
    </a>
  </div>
</template>

<script>
  export default {
    name: "NoResultsMessage",
    props: {
      message: String,
      showClearFilter: Boolean,
    },
  };
</script>
