<template>
  <div class="text-white relative" :class="{ 'text-center': isCollapsed }">
    <!-- User menu toggle button -->
    <button
      @click="toggleMenu"
      class="flex items-center w-full"
      :class="{ 'justify-center': isCollapsed, 'justify-start': !isCollapsed }"
    >
      <div class="profile-avatar" :class="{ 'w-8 h-8': isCollapsed }">
        {{ isCollapsed ? userInitials[0] : userInitials }}
      </div>
      <span v-if="!isCollapsed" class="ml-2 truncate">{{
        user.displayName || user.email
      }}</span>
    </button>

    <!-- User menu dropdown -->
    <transition name="menu-fade">
      <div
        v-if="isMenuOpen"
        :class="dropdownClasses"
        class="py-2 bg-gray-700 rounded-md shadow-lg absolute z-50"
      >
        <!-- Menu items -->
        <div
          v-if="userPlan === 'Premium'"
          class="px-4 py-2 text-sm whitespace-nowrap"
        >
          Plan: Premium
        </div>
        <a
          v-else
          href="#"
          @click.prevent="upgradeToPremium"
          class="block px-4 py-2 text-sm hover:bg-gray-600 cursor-pointer whitespace-nowrap"
        >
          {{ isUpgrading ? "Upgrading..." : "Upgrade to Premium" }}
        </a>
        <a
          href="#"
          @click.prevent="openSettings"
          class="block px-4 py-2 text-sm hover:bg-gray-600 whitespace-nowrap"
          >Settings</a
        >
        <a
          href="#"
          @click.prevent="logout"
          class="block px-4 py-2 text-sm hover:bg-gray-600 whitespace-nowrap"
          >Logout</a
        >
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "UserMenu",
    props: {
      user: Object,
      userPlan: String,
      isUpgrading: Boolean,
      isCollapsed: Boolean,
    },
    data() {
      return {
        isMenuOpen: false,
      };
    },
    computed: {
      userInitials() {
        if (this.user.displayName) {
          return this.user.displayName
            .split(" ")
            .map((name) => name[0])
            .join("")
            .toUpperCase();
        } else {
          return this.user.email[0].toUpperCase();
        }
      },
      dropdownClasses() {
        if (this.isCollapsed) {
          return {
            "left-full": true,
            "top-0": true,
            transform: true,
            "-translate-y-full": true,
            "w-48": true,
          };
        } else {
          return {
            "bottom-full": true,
            "left-0": true,
            "mb-2": true,
            "w-48": true,
          };
        }
      },
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      openSettings() {
        this.$emit("open-settings");
        this.isMenuOpen = false;
      },
      logout() {
        this.$emit("logout");
        this.isMenuOpen = false;
      },
      upgradeToPremium() {
        this.$emit("upgrade-to-premium");
        this.isMenuOpen = false;
      },
    },
    watch: {
      isCollapsed() {
        this.isMenuOpen = false;
      },
    },
  };
</script>

<style scoped>
  .profile-avatar {
    width: 2rem;
    height: 2rem;
    background-color: #4a5568;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .menu-fade-enter-active,
  .menu-fade-leave-active {
    transition: opacity 0.2s, transform 0.2s;
  }

  .menu-fade-enter-from,
  .menu-fade-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }
</style>
