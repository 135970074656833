<template>
  <div>
    <!-- Add search input -->
    <input
      v-model="searchQuery"
      type="text"
      placeholder="Filter across all sections..."
      class="w-full p-2 mb-4 border rounded-md"
    />
    <div class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4">
      <!-- Income Section -->
      <div class="bg-green-50 p-3 rounded-lg shadow-md flex flex-col h-full">
        <div class="flex justify-between items-center mb-2">
          <h2 class="font-bold text-xl text-green-700">Income</h2>
          <button
            @click="openAddModal('income')"
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded text-sm"
          >
            Add
          </button>
        </div>
        <div v-if="isLoadingIncome" class="space-y-2">
          <div v-for="n in 3" :key="n" class="animate-pulse flex items-center">
            <div class="h-4 bg-green-200 rounded w-2/3"></div>
            <div class="h-4 bg-green-200 rounded w-1/3 ml-2"></div>
          </div>
        </div>
        <NoResultsMessage
          v-else-if="filteredIncome.length === 0"
          :message="
            income.length > 0
              ? 'No matching entries.'
              : 'No income entries yet.'
          "
          :show-clear-filter="income.length > 0"
          @clear-filter="clearFilter"
        />
        <div v-else class="space-y-1 flex-grow overflow-y-auto">
          <div
            v-for="(item, index) in filteredIncome"
            :key="item.id"
            class="flex items-center justify-between py-1 group relative"
            :class="{
              'border-b border-green-100': index !== filteredIncome.length - 1,
              'opacity-30': item.filtered,
            }"
          >
            <div class="flex items-center w-full">
              <span
                class="w-2/3 group-hover:font-bold transition-all duration-200"
                v-html="highlightMatch(item.name)"
              ></span>
              <span
                class="w-1/3 text-right group-hover:invisible transition-all duration-200"
                v-html="
                  highlightMatch(formatCurrency(item.amount, preferredCurrency))
                "
              ></span>
            </div>
            <div
              class="absolute inset-y-0 right-0 flex items-center justify-end opacity-0 group-hover:opacity-100 transition-opacity duration-200"
            >
              <button
                @click="openEditModal(item)"
                class="text-gray-400 hover:text-blue-600 p-1 mx-1"
                title="Edit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
              </button>
              <button
                @click="deleteItem(item)"
                class="text-gray-400 hover:text-red-600 p-1 mx-1"
                title="Delete"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-4 pt-2 border-t border-green-200">
          <div class="flex justify-between text-sm font-medium">
            <span>Total Income:</span>
            <span>{{ formatCurrency(totalIncome, preferredCurrency) }}</span>
          </div>
        </div>
      </div>

      <!-- Savings Section -->
      <div class="bg-orange-50 p-3 rounded-lg shadow-md flex flex-col h-full">
        <div class="flex justify-between items-center mb-2">
          <h2 class="font-bold text-xl text-orange-700">Savings</h2>
          <button
            @click="openAddModal('savings')"
            class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-1 px-2 rounded text-sm"
          >
            Add
          </button>
        </div>
        <div v-if="isLoadingSavings" class="space-y-2">
          <div v-for="n in 3" :key="n" class="animate-pulse flex items-center">
            <div class="h-3 w-3 bg-orange-200 rounded-full mr-2"></div>
            <div class="h-4 bg-orange-200 rounded w-2/3"></div>
            <div class="h-4 bg-orange-200 rounded w-1/3 ml-2"></div>
          </div>
        </div>
        <NoResultsMessage
          v-else-if="filteredSavings.length === 0"
          :message="
            savings.length > 0
              ? 'No matching entries.'
              : 'No savings entries yet.'
          "
          :show-clear-filter="savings.length > 0"
          @clear-filter="clearFilter"
        />
        <div v-else class="space-y-1 flex-grow overflow-y-auto">
          <div
            v-for="(item, index) in filteredSavings"
            :key="item.id"
            class="flex items-center justify-between py-1 group relative"
            :class="{
              'border-b border-orange-100':
                index !== filteredSavings.length - 1,
              'opacity-30': item.filtered,
            }"
          >
            <div
              :class="[
                'w-3 h-3 rounded-full mr-2 flex-shrink-0',
                isItemDebited(item) ? 'bg-green-500' : 'bg-gray-300',
              ]"
              v-tooltip="
                getTooltipOptions(
                  isItemDebited(item) ? 'Debited' : 'Not yet debited'
                )
              "
            ></div>
            <div class="flex-grow flex items-center justify-between">
              <span
                class="w-2/3 group-hover:font-bold transition-all duration-200"
                v-html="highlightMatch(item.name)"
              ></span>
              <span
                class="w-1/3 text-right group-hover:invisible"
                v-html="
                  highlightMatch(formatCurrency(item.amount, preferredCurrency))
                "
              ></span>
            </div>
            <div
              class="absolute inset-y-0 right-0 flex items-center justify-end opacity-0 group-hover:opacity-100"
            >
              <button
                @click="openEditModal(item)"
                class="text-gray-400 hover:text-blue-600 p-1 mx-1"
                title="Edit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
              </button>
              <button
                @click="deleteItem(item)"
                class="text-gray-400 hover:text-red-600 p-1 mx-1"
                title="Delete"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-4 pt-2 border-t border-orange-200">
          <div class="flex justify-between text-sm font-medium">
            <span>Total Savings:</span>
            <span>{{ formatCurrency(totalSavings, preferredCurrency) }}</span>
          </div>
        </div>
      </div>

      <!-- Expenses Section -->
      <div class="bg-red-50 p-3 rounded-lg shadow-md flex flex-col h-full">
        <div class="flex justify-between items-center mb-2">
          <h2 class="font-bold text-xl text-red-700">Expenses</h2>
          <button
            @click="openAddModal('expenses')"
            class="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded text-sm"
          >
            Add
          </button>
        </div>
        <div v-if="isLoadingExpenses" class="space-y-2">
          <div v-for="n in 3" :key="n" class="animate-pulse flex items-center">
            <div class="h-3 w-3 bg-red-200 rounded-full mr-2"></div>
            <div class="h-4 bg-red-200 rounded w-2/3"></div>
            <div class="h-4 bg-red-200 rounded w-1/3 ml-2"></div>
          </div>
        </div>
        <NoResultsMessage
          v-else-if="filteredExpenses.length === 0"
          :message="
            expenses.length > 0
              ? 'No matching entries.'
              : 'No expenses recorded yet.'
          "
          :show-clear-filter="expenses.length > 0"
          @clear-filter="clearFilter"
        />
        <div v-else class="space-y-1 flex-grow overflow-y-auto">
          <div
            v-for="(expense, index) in filteredExpenses"
            :key="expense.id"
            class="flex items-center justify-between py-1 group relative"
            :class="{
              'border-b border-red-100': index !== filteredExpenses.length - 1,
              'opacity-30': expense.filtered,
            }"
          >
            <div
              :class="[
                'w-3 h-3 rounded-full mr-2 flex-shrink-0',
                isItemDebited(expense) ? 'bg-green-500' : 'bg-gray-300',
              ]"
              v-tooltip="
                getTooltipOptions(
                  isItemDebited(expense) ? 'Debited' : 'Not yet debited'
                )
              "
            ></div>
            <div class="flex-grow flex items-center justify-between">
              <span
                class="w-2/3 group-hover:font-bold transition-all duration-200"
                v-html="highlightMatch(expense.name)"
              ></span>
              <span
                class="w-1/3 text-right group-hover:invisible"
                v-html="
                  highlightMatch(
                    formatCurrency(expense.amount, preferredCurrency)
                  )
                "
              ></span>
            </div>
            <div
              class="absolute inset-y-0 right-0 flex items-center justify-end opacity-0 group-hover:opacity-100"
            >
              <button
                @click="openEditModal(expense)"
                class="text-gray-400 hover:text-blue-600 p-1 mx-1"
                title="Edit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                  />
                </svg>
              </button>
              <button
                @click="deleteItem(expense)"
                class="text-gray-400 hover:text-red-600 p-1 mx-1"
                title="Delete"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-4 pt-2 border-t border-red-200">
          <div class="flex justify-between text-sm font-medium">
            <span>Total Expenses:</span>
            <span>{{ formatCurrency(totalExpenses, preferredCurrency) }}</span>
          </div>
        </div>
      </div>

      <!-- Summary Section -->
      <div class="bg-gray-50 p-3 rounded-lg shadow-md flex flex-col">
        <h2 class="font-bold text-xl mb-3 text-gray-800">Summary</h2>
        <div
          v-if="isLoadingIncome || isLoadingSavings || isLoadingExpenses"
          class="space-y-2"
        >
          <div
            v-for="n in 3"
            :key="n"
            class="animate-pulse flex justify-between"
          >
            <div class="h-4 bg-gray-200 rounded w-1/3"></div>
            <div class="h-4 bg-gray-200 rounded w-1/4"></div>
          </div>
        </div>
        <div v-else class="space-y-2 flex-grow">
          <div class="flex justify-between">
            <span class="text-gray-600">Total Debited:</span>
            <span class="font-medium">{{
              formatCurrency(totalDebited, preferredCurrency)
            }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-600">Total Undebited:</span>
            <span class="font-medium">{{
              formatCurrency(totalUndebited, preferredCurrency)
            }}</span>
          </div>
        </div>

        <div class="mt-4 pt-2 border-t border-gray-200 flex flex-col">
          <div class="flex justify-between text-sm font-semibold">
            <span>Net:</span>
            <span
              :class="{ 'text-red-500': net < 0 }"
              v-tooltip="
                getTooltipOptions(
                  net < 0 ? 'Your expenses exceed your income' : ''
                )
              "
            >
              {{ formatCurrency(net, preferredCurrency) }}
            </span>
          </div>
        </div>
      </div>

      <!-- Add this section after the main financial summary -->
      <FinancialHealthScore
        v-if="!isLoadingIncome && !isLoadingSavings && !isLoadingExpenses"
        :totalIncome="totalIncome"
        :totalSavings="totalSavings"
        :totalExpenses="totalExpenses"
        :net="net"
        class="lg:col-span-4 2xl:col-span-4"
      />

      <!-- Add the modal component -->
      <EditItemModal
        v-if="showEditModal"
        :show="showEditModal"
        :item="selectedItem"
        :isAdding="isAdding"
        :preferred-currency="preferredCurrency"
        :existing-items="getExistingItems(selectedItem.type)"
        :isSaving="isSaving"
        @close="closeEditModal"
        @save="saveItem"
      />

      <!-- Add the confirmation modal -->
      <ConfirmationModal
        :show="showDeleteConfirmation"
        title="Confirm Deletion"
        :message="deleteConfirmationMessage"
        @confirm="confirmDelete"
        @cancel="cancelDelete"
      />
    </div>
  </div>
</template>

<script>
  import { auth, db } from "../firebase";
  import {
    collection,
    onSnapshot,
    query,
    where,
    deleteDoc,
    doc,
    updateDoc,
    addDoc,
  } from "firebase/firestore";
  import EditItemModal from "./EditItemModal.vue";
  import ConfirmationModal from "./ConfirmationModal.vue";
  import NoResultsMessage from "./NoResultsMessage.vue";
  import FinancialHealthScore from "./FinancialHealthScore.vue";

  export default {
    name: "Dashboard",
    components: {
      EditItemModal,
      ConfirmationModal,
      NoResultsMessage,
      FinancialHealthScore,
    },
    props: {
      preferredCurrency: {
        type: String,
        default: "USD",
      },
    },
    data() {
      return {
        income: [],
        savings: [],
        expenses: [],
        isLoadingIncome: true,
        isLoadingSavings: true,
        isLoadingExpenses: true,
        showEditModal: false,
        selectedItem: null,
        isAdding: false,
        unsubscribeIncome: null,
        unsubscribeSavings: null,
        unsubscribeExpenses: null,
        isSaving: false,
        showDeleteConfirmation: false,
        itemToDelete: null,
        deleteConfirmationMessage: "",
        searchQuery: "",
      };
    },
    computed: {
      totalIncome() {
        return this.income.reduce(
          (sum, item) => sum + (Number(item.amount) || 0),
          0
        );
      },
      totalSavings() {
        return this.savings.reduce(
          (sum, item) => sum + (Number(item.amount) || 0),
          0
        );
      },
      totalExpenses() {
        return this.expenses.reduce(
          (sum, item) => sum + (Number(item.amount) || 0),
          0
        );
      },
      totalDebitedSavings() {
        const currentDay = new Date().getDate();
        return this.savings
          .filter((s) => currentDay >= s.debitDay)
          .reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
      },
      totalDebitedExpenses() {
        const currentDay = new Date().getDate();
        return this.expenses
          .filter((e) => currentDay >= e.debitDay)
          .reduce((sum, item) => sum + (Number(item.amount) || 0), 0);
      },
      totalDebited() {
        return this.totalDebitedSavings + this.totalDebitedExpenses;
      },
      totalUndebited() {
        return this.totalSavings + this.totalExpenses - this.totalDebited;
      },
      net() {
        return this.totalIncome - this.totalSavings - this.totalExpenses;
      },
      filteredIncome() {
        return this.applyFilter(this.income);
      },
      filteredSavings() {
        return this.applyFilter(this.savings);
      },
      filteredExpenses() {
        return this.applyFilter(this.expenses);
      },
    },
    created() {
      console.log("Dashboard component created");
      auth.onAuthStateChanged((user) => {
        if (user) {
          console.log("User authenticated, setting up Firestore listeners");
          this.setupFirestoreListeners(user.uid);
        } else {
          console.log("User not authenticated, redirecting to login");
          this.$router.push("/login");
        }
      });
    },
    beforeDestroy() {
      if (this.unsubscribeIncome) this.unsubscribeIncome();
      if (this.unsubscribeSavings) this.unsubscribeSavings();
      if (this.unsubscribeExpenses) this.unsubscribeExpenses();
    },
    methods: {
      setupFirestoreListeners(userId) {
        console.log("Setting up Firestore listeners for user:", userId);
        this.setupCollectionListener("income", userId);
        this.setupCollectionListener("savings", userId);
        this.setupCollectionListener("expenses", userId);
      },
      setupCollectionListener(collectionName, userId) {
        console.log(`Setting up listener for ${collectionName}`);
        const q = query(
          collection(db, collectionName),
          where("userId", "==", userId)
        );
        this[
          `unsubscribe${
            collectionName.charAt(0).toUpperCase() + collectionName.slice(1)
          }`
        ] = onSnapshot(
          q,
          (snapshot) => {
            this[collectionName] = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            this[
              `isLoading${
                collectionName.charAt(0).toUpperCase() + collectionName.slice(1)
              }`
            ] = false;
          },
          (error) => {
            console.error(`Error fetching ${collectionName}:`, error);
            this[
              `isLoading${
                collectionName.charAt(0).toUpperCase() + collectionName.slice(1)
              }`
            ] = false;
          }
        );
      },
      openAddModal(type) {
        this.selectedItem = {
          type,
          name: "",
          amount: 0,
          debitDay: 1,
        };
        this.isAdding = true;
        this.showEditModal = true;
      },
      openEditModal(item) {
        this.selectedItem = { ...item, type: this.getItemType(item) };
        this.isAdding = false;
        this.showEditModal = true;
      },
      closeEditModal() {
        this.showEditModal = false;
        this.selectedItem = null;
        this.isAdding = false;
      },
      async saveItem(item) {
        this.isSaving = true;
        try {
          if (this.isAdding) {
            await addDoc(collection(db, item.type), {
              name: item.name,
              amount: item.amount,
              debitDay: item.debitDay,
              userId: auth.currentUser.uid,
            });
          } else {
            const itemRef = doc(db, item.type, item.id);
            await updateDoc(itemRef, {
              name: item.name,
              amount: item.amount,
              debitDay: item.debitDay,
            });
          }
          this.closeEditModal();
        } catch (error) {
          console.error("Error saving item:", error);
          alert("Failed to save item. Please try again.");
        } finally {
          this.isSaving = false;
        }
      },
      getItemType(item) {
        if (this.income.some((i) => i.id === item.id)) return "income";
        if (this.savings.some((s) => s.id === item.id)) return "savings";
        if (this.expenses.some((e) => e.id === item.id)) return "expenses";
        return "";
      },
      deleteItem(item) {
        const itemType = this.getItemType(item);
        if (!itemType) {
          console.error("Invalid item type");
          return;
        }

        this.itemToDelete = item;
        this.deleteConfirmationMessage = `Are you sure you want to delete this ${itemType} entry?`;
        this.showDeleteConfirmation = true;
      },

      async confirmDelete() {
        if (!this.itemToDelete) return;

        const itemType = this.getItemType(this.itemToDelete);
        try {
          await deleteDoc(doc(db, itemType, this.itemToDelete.id));
          console.log(`${itemType} item deleted successfully`);
        } catch (error) {
          console.error("Error deleting item:", error);
          alert("Failed to delete item. Please try again.");
        } finally {
          this.cancelDelete();
        }
      },

      cancelDelete() {
        this.showDeleteConfirmation = false;
        this.itemToDelete = null;
        this.deleteConfirmationMessage = "";
      },

      isItemDebited(item) {
        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        return currentDay >= item.debitDay;
      },
      formatCurrency(amount, currencyCode) {
        try {
          return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: currencyCode,
          }).format(amount);
        } catch (error) {
          console.error(`Invalid currency code: ${currencyCode}`);
          return amount;
        }
      },
      getExistingItems(type) {
        switch (type) {
          case "income":
            return this.income;
          case "savings":
            return this.savings;
          case "expenses":
            return this.expenses;
          default:
            return [];
        }
      },
      getTooltipOptions(content) {
        return {
          content,
          delay: { show: 5, hide: 5 },
          classes: "custom-tooltip-theme",
          autoHide: true,
          boundariesElement: document.body,
        };
      },
      clearFilter() {
        this.searchQuery = "";
      },
      applyFilter(items) {
        const query = this.searchQuery.toLowerCase().trim();
        return items
          .map((item) => ({
            ...item,
            filtered: query
              ? !(
                  item?.name?.toLowerCase().includes(query) ||
                  item?.description?.toLowerCase().includes(query) ||
                  this.matchAmount(Number(item.amount), query)
                )
              : false,
          }))
          .sort((a, b) => Number(b.amount) - Number(a.amount));
      },
      matchAmount(amount, query) {
        const amountStr = amount.toFixed(2); // Ensure two decimal places
        const cleanQuery = query.replace(/[^0-9.]/g, "");

        if (!cleanQuery) return false;

        // Check for exact match
        if (amountStr === cleanQuery) return true;

        // Check if query is a substring of the amount
        if (amountStr.includes(cleanQuery)) return true;

        // Check if query matches the amount ignoring decimal point
        const amountWithoutDecimal = amountStr.replace(".", "");
        const queryWithoutDecimal = cleanQuery.replace(".", "");
        return amountWithoutDecimal.includes(queryWithoutDecimal);
      },
      highlightMatch(text) {
        if (!this.searchQuery) return text;

        // Check if the text is a formatted currency amount
        if (text.includes(this.preferredCurrency)) {
          return this.highlightAmount(text);
        }

        const regex = new RegExp(
          `(${this.escapeRegExp(this.searchQuery)})`,
          "gi"
        );
        return text.replace(regex, "<strong>$1</strong>");
      },

      highlightAmount(formattedAmount) {
        const amount = formattedAmount.replace(/[^0-9.]/g, "");
        const query = this.searchQuery.replace(/[^0-9.]/g, "");

        if (amount.includes(query)) {
          const parts = formattedAmount.split(query);
          return parts.join(`<strong>${query}</strong>`);
        }

        return formattedAmount;
      },

      escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      },
    },
  };
</script>

<style scoped>
  strong {
    font-weight: bold;
    background-color: yellow;
  }
</style>
