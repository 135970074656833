<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "App",
  };
</script>

<style>
  .custom-tooltip-theme {
    display: block !important;
    z-index: 10000;
  }

  .custom-tooltip-theme .tooltip-inner {
    background: #333;
    color: white;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .custom-tooltip-theme .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #333;
  }

  .custom-tooltip-theme[x-placement^="top"] {
    margin-bottom: 5px;
  }

  .custom-tooltip-theme[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  /* Add more tooltip placement styles as needed */
</style>
