<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <h2 class="text-2xl font-bold mb-4">
        {{ isAdding ? "Add" : "Edit" }} {{ item.type }}
      </h2>
      <form @submit.prevent="save" class="space-y-4">
        <div>
          <label for="name" class="block mb-2">Name</label>
          <input
            id="name"
            ref="nameInput"
            v-model="localName"
            type="text"
            class="w-full px-3 py-2 border rounded"
            :class="{ 'border-red-500': nameError }"
            required
          />
          <p v-if="nameError" class="text-red-500 text-sm mt-1">
            {{ nameError }}
          </p>
        </div>
        <div>
          <label for="amount" class="block mb-2"
            >Amount ({{ preferredCurrency }})</label
          >
          <input
            id="amount"
            v-model="localAmount"
            type="number"
            step="0.01"
            class="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div v-if="item.type !== 'income'">
          <label for="debitDay" class="block mb-2">Debit Day</label>
          <select
            id="debitDay"
            v-model="localDebitDay"
            class="w-full px-3 py-2 border rounded"
          >
            <option v-for="day in 31" :key="day" :value="day">{{ day }}</option>
          </select>
        </div>
        <div class="flex justify-end">
          <button
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            :disabled="!isFormValid || isSaving"
            :class="{
              'opacity-50 cursor-not-allowed': !isFormValid || isSaving,
            }"
          >
            <span v-if="!isSaving">Save</span>
            <span v-else class="flex items-center">
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Saving...
            </span>
          </button>
          <button
            type="button"
            @click="$emit('close')"
            class="bg-gray-300 text-gray-800 px-4 py-2 rounded"
            :disabled="isSaving"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "EditItemModal",
    props: {
      show: Boolean,
      item: Object,
      isAdding: Boolean,
      preferredCurrency: String,
      existingItems: {
        type: Array,
        default: () => [],
      },
      isSaving: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        localName: this.item.name,
        localAmount: this.item.amount,
        localDebitDay: this.item.debitDay || 1,
        nameError: "",
      };
    },
    computed: {
      isFormValid() {
        return this.localName.trim() !== "" && !this.nameError;
      },
      isDuplicateName() {
        const trimmedName = this.localName.trim().toLowerCase();
        return this.existingItems.some(
          (item) =>
            item.name.toLowerCase() === trimmedName && item.id !== this.item.id
        );
      },
    },
    methods: {
      save() {
        if (this.localName.trim() === "") {
          this.nameError = "Name cannot be blank";
          return;
        }

        if (this.isDuplicateName) {
          this.nameError = "This name already exists in this category";
          return;
        }

        const updatedItem = {
          ...this.item,
          name: this.localName.trim(),
          amount: parseFloat(this.localAmount),
          debitDay: parseInt(this.localDebitDay),
        };
        this.$emit("save", updatedItem);
      },
      focusNameInput() {
        this.$nextTick(() => {
          if (this.$refs.nameInput) {
            this.$refs.nameInput.focus();
          }
        });
      },
    },
    watch: {
      show(newValue) {
        if (newValue) {
          this.focusNameInput();
        }
      },
      item(newItem) {
        this.localName = newItem.name;
        this.localAmount = newItem.amount;
        this.localDebitDay = newItem.debitDay || 1;
        this.focusNameInput();
      },
      localName(newValue) {
        if (newValue.trim() === "") {
          this.nameError = "Name cannot be blank";
        } else if (this.isDuplicateName) {
          this.nameError = "This name already exists in this category";
        } else {
          this.nameError = "";
        }
      },
    },
    mounted() {
      if (this.show) {
        this.focusNameInput();
      }
    },
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 90%;
    max-width: 500px;
  }
</style>
