<template>
  <div class="academy-container">
    <div class="content-wrapper">
      <div class="overlay mx-2 my-2 sm:mx-6 sm:my-6 p-3 sm:p-6">
        <h2 class="text-xl sm:text-3xl font-bold mb-3 sm:mb-4 text-white">
          Cently Academy
        </h2>
        <p class="text-base sm:text-xl mb-4 sm:mb-6 text-white">
          Master the art of budgeting and personal finance with our
          comprehensive learning platform.
        </p>
        <ul
          class="text-left text-white mb-4 sm:mb-6 space-y-1 sm:space-y-2 text-sm sm:text-base"
        >
          <li class="flex items-center">
            <svg
              class="w-5 h-5 mr-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            Learn essential budgeting techniques
          </li>
          <li class="flex items-center">
            <svg
              class="w-5 h-5 mr-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            Understand personal finance fundamentals
          </li>
          <li class="flex items-center">
            <svg
              class="w-5 h-5 mr-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            Discover strategies for saving and investing
          </li>
          <li class="flex items-center">
            <svg
              class="w-5 h-5 mr-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            Access expert tips for financial planning
          </li>
        </ul>
        <p class="text-base sm:text-xl mb-4 sm:mb-6 text-white">Coming soon!</p>
        <div v-if="user && !isLoading">
          <div v-if="!isNotified">
            <button
              @click="notifyUser"
              class="bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-2 sm:py-3 px-4 sm:px-6 rounded-full text-sm sm:text-lg shadow-lg transform transition hover:scale-105 w-full sm:w-auto"
              :disabled="isUpdating"
            >
              {{
                isUpdating ? "Processing..." : "Notify me when Academy launches"
              }}
            </button>
          </div>
          <div v-else>
            <p class="text-green-400 text-sm sm:text-base">
              You will be notified when the Academy launches!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from "vue";
  import { auth, db } from "@/firebase";
  import { doc, getDoc, updateDoc } from "firebase/firestore";

  export default {
    name: "Academy",
    setup() {
      const user = ref(null);
      const isNotified = ref(false);
      const isLoading = ref(true);
      const isUpdating = ref(false);

      onMounted(async () => {
        user.value = auth.currentUser;
        if (user.value) {
          try {
            const userDoc = await getDoc(doc(db, "users", user.value.uid));
            if (userDoc.exists()) {
              isNotified.value = userDoc.data().notifyAcademyLaunch || false;
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          } finally {
            isLoading.value = false;
          }
        } else {
          isLoading.value = false;
        }
      });

      const notifyUser = async () => {
        if (isUpdating.value) return;

        isUpdating.value = true;
        try {
          const userRef = doc(db, "users", user.value.uid);
          await updateDoc(userRef, { notifyAcademyLaunch: true });
          isNotified.value = true;
        } catch (error) {
          console.error(
            "Failed to update user notification preference:",
            error
          );
          // Handle error (e.g., show an error message to the user)
        } finally {
          isUpdating.value = false;
        }
      };

      return {
        user,
        isNotified,
        isLoading,
        isUpdating,
        notifyUser,
      };
    },
  };
</script>

<style scoped>
  .academy-container {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("@/assets/academy_bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    min-height: calc(
      100vh - 64px
    ); /* Adjust this value based on your header height */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  .content-wrapper {
    max-width: 100%;
    width: 100%;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  }

  @media (min-width: 640px) {
    .content-wrapper {
      max-width: 800px;
    }

    .overlay {
      border-radius: 16px;
    }
  }

  /* Add this new media query for very small screens */
  @media (max-width: 360px) {
    .overlay {
      padding: 1rem;
    }
  }
</style>
