<template>
  <div>
    <!-- Header section -->
    <header class="bg-white shadow-sm">
      <div
        class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center"
      >
        <router-link to="/" class="flex items-center">
          <img
            src="@/assets/logo.svg"
            alt="Cently Logo"
            class="h-[50px] w-auto"
          />
        </router-link>
        <nav>
          <router-link
            to="/login"
            class="text-gray-600 hover:text-gray-900 ml-4"
            >Login</router-link
          >
          <router-link
            to="/signup"
            class="bg-blue-600 text-white px-4 py-2 rounded-md ml-4 hover:bg-blue-700 transition duration-5"
            >Sign Up</router-link
          >
        </nav>
      </div>
    </header>

    <!-- Login form -->
    <div class="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
      <h2 class="text-2xl font-bold mb-6 text-center">Login</h2>
      <form @submit.prevent="login" class="space-y-4">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email</label
          >
          <input
            v-model="email"
            id="email"
            type="email"
            placeholder="Email"
            required
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700"
            >Password</label
          >
          <input
            v-model="password"
            id="password"
            type="password"
            placeholder="Password"
            required
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              v-model="rememberMe"
              id="remember-me"
              type="checkbox"
              class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label for="remember-me" class="ml-2 block text-sm text-gray-900">
              Remember me
            </label>
          </div>
          <router-link
            to="/forgot-password"
            class="text-sm text-blue-500 hover:underline"
          >
            Forgot Password?
          </router-link>
        </div>
        <button
          type="submit"
          :disabled="isLoading"
          class="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {{ isLoading ? "Logging in..." : "Login" }}
        </button>
      </form>
      <div class="mt-4">
        <button
          @click="loginWithGoogle"
          :disabled="isLoading"
          class="w-full mt-4 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {{ isLoading ? "Processing..." : "Login with Google" }}
        </button>
      </div>
      <p v-if="errorMessage" class="mt-4 text-red-500 text-center">
        {{ errorMessage }}
      </p>
      <p class="mt-4 text-center">
        Don't have an account?
        <router-link to="/signup" class="text-blue-500 hover:underline"
          >Sign up</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
  import { auth, googleProvider } from "@/firebase";
  import {
    signInWithEmailAndPassword,
    signInWithPopup,
    setPersistence,
    browserSessionPersistence,
    browserLocalPersistence,
  } from "firebase/auth";

  export default {
    name: "Login",
    data() {
      return {
        email: "",
        password: "",
        rememberMe: false,
        isLoading: false,
        errorMessage: "",
      };
    },
    methods: {
      async login() {
        this.isLoading = true;
        this.errorMessage = "";
        try {
          await setPersistence(
            auth,
            this.rememberMe
              ? browserLocalPersistence
              : browserSessionPersistence
          );
          await signInWithEmailAndPassword(auth, this.email, this.password);
          this.$router.push("/auth/dashboard");
        } catch (error) {
          console.error("Login error:", error);
          this.errorMessage = this.getErrorMessage(error.code);
        } finally {
          this.isLoading = false;
        }
      },
      async loginWithGoogle() {
        this.isLoading = true;
        this.errorMessage = "";
        try {
          await signInWithPopup(auth, googleProvider);
          this.$router.push("/auth/dashboard");
        } catch (error) {
          console.error("Google login error:", error);
          this.errorMessage = this.getErrorMessage(error.code);
        } finally {
          this.isLoading = false;
        }
      },
      getErrorMessage(errorCode) {
        switch (errorCode) {
          case "auth/user-not-found":
          case "auth/wrong-password":
            return "Invalid email or password";
          case "auth/too-many-requests":
            return "Too many failed login attempts. Please try again later";
          default:
            return "An error occurred. Please try again";
        }
      },
    },
  };
</script>
