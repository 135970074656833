<template>
  <div class="authenticated-layout">
    <!-- Sidebar -->
    <aside :class="sidebarClasses">
      <div class="p-4 flex items-center">
        <svg
          v-if="isMobile"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          height="50"
          zoomAndPan="magnify"
          viewBox="0 0 150 149.999998"
          preserveAspectRatio="xMidYMid meet"
          version="1.0"
        >
          <defs><g /></defs>
          <g fill="white" fill-opacity="1">
            <g transform="translate(11.285111, 126.63819)">
              <g>
                <path
                  d="M 65.984375 -22.75 C 72.140625 -22.75 77.160156 -24.007812 81.046875 -26.53125 C 84.941406 -29.0625 87.570312 -32.648438 88.9375 -37.296875 L 115.578125 -37.296875 C 114.628906 -26.910156 110.15625 -18.300781 102.15625 -11.46875 C 94.164062 -4.644531 84.160156 -0.6875 72.140625 0.40625 L 72.140625 17.828125 L 59.015625 17.828125 L 59.015625 0.40625 C 43.859375 -0.957031 32.109375 -6.488281 23.765625 -16.1875 C 15.429688 -25.882812 11.265625 -38.113281 11.265625 -52.875 C 11.265625 -67.625 15.429688 -79.847656 23.765625 -89.546875 C 32.109375 -99.253906 43.859375 -104.789062 59.015625 -106.15625 L 59.015625 -123.578125 L 72.140625 -123.578125 L 72.140625 -106.15625 C 84.160156 -105.0625 94.164062 -101.097656 102.15625 -94.265625 C 110.15625 -87.441406 114.628906 -78.835938 115.578125 -68.453125 L 88.9375 -68.453125 C 85.800781 -78.148438 78.148438 -83 65.984375 -83 C 57.515625 -83 50.820312 -80.332031 45.90625 -75 C 40.988281 -69.675781 38.53125 -62.300781 38.53125 -52.875 C 38.53125 -43.445312 40.988281 -36.066406 45.90625 -30.734375 C 50.820312 -25.410156 57.515625 -22.75 65.984375 -22.75 Z M 65.984375 -22.75 "
                />
              </g>
            </g>
          </g>
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          zoomAndPan="magnify"
          viewBox="0 0 224.87999 82.499998"
          height="50"
          preserveAspectRatio="xMidYMid meet"
          version="1.0"
        >
          <defs><g /></defs>
          <g fill="white" fill-opacity="1">
            <g transform="translate(52.142397, 59.447747)">
              <g>
                <path
                  d="M 36.71875 -16.359375 C 36.71875 -16.242188 36.65625 -15.390625 36.53125 -13.796875 L 11.578125 -13.796875 C 12.035156 -11.753906 13.097656 -10.140625 14.765625 -8.953125 C 16.441406 -7.765625 18.53125 -7.171875 21.03125 -7.171875 C 22.738281 -7.171875 24.253906 -7.425781 25.578125 -7.9375 C 26.910156 -8.445312 28.148438 -9.253906 29.296875 -10.359375 L 34.390625 -4.84375 C 31.285156 -1.289062 26.75 0.484375 20.78125 0.484375 C 17.0625 0.484375 13.769531 -0.238281 10.90625 -1.6875 C 8.050781 -3.132812 5.847656 -5.144531 4.296875 -7.71875 C 2.742188 -10.289062 1.96875 -13.210938 1.96875 -16.484375 C 1.96875 -19.710938 2.734375 -22.625 4.265625 -25.21875 C 5.796875 -27.8125 7.898438 -29.832031 10.578125 -31.28125 C 13.253906 -32.738281 16.242188 -33.46875 19.546875 -33.46875 C 22.773438 -33.46875 25.695312 -32.769531 28.3125 -31.375 C 30.9375 -29.988281 32.992188 -28 34.484375 -25.40625 C 35.972656 -22.8125 36.71875 -19.796875 36.71875 -16.359375 Z M 19.609375 -26.234375 C 17.441406 -26.234375 15.625 -25.617188 14.15625 -24.390625 C 12.6875 -23.171875 11.789062 -21.5 11.46875 -19.375 L 27.703125 -19.375 C 27.378906 -21.457031 26.476562 -23.117188 25 -24.359375 C 23.53125 -25.609375 21.734375 -26.234375 19.609375 -26.234375 Z M 19.609375 -26.234375 "
                />
              </g>
            </g>
          </g>
          <g fill="white" fill-opacity="1">
            <g transform="translate(90.819005, 59.447747)">
              <g>
                <path
                  d="M 24.453125 -33.46875 C 28.546875 -33.46875 31.847656 -32.238281 34.359375 -29.78125 C 36.867188 -27.332031 38.125 -23.695312 38.125 -18.875 L 38.125 0 L 28.5625 0 L 28.5625 -17.40625 C 28.5625 -20.019531 27.988281 -21.96875 26.84375 -23.25 C 25.695312 -24.539062 24.046875 -25.1875 21.890625 -25.1875 C 19.472656 -25.1875 17.546875 -24.441406 16.109375 -22.953125 C 14.679688 -21.460938 13.96875 -19.25 13.96875 -16.3125 L 13.96875 0 L 4.40625 0 L 4.40625 -32.984375 L 13.546875 -32.984375 L 13.546875 -29.109375 C 14.816406 -30.503906 16.390625 -31.578125 18.265625 -32.328125 C 20.148438 -33.085938 22.210938 -33.46875 24.453125 -33.46875 Z M 24.453125 -33.46875 "
                />
              </g>
            </g>
          </g>
          <g fill="white" fill-opacity="1">
            <g transform="translate(133.173266, 59.447747)">
              <g>
                <path
                  d="M 25.6875 -1.59375 C 24.75 -0.894531 23.59375 -0.375 22.21875 -0.03125 C 20.851562 0.3125 19.414062 0.484375 17.90625 0.484375 C 13.976562 0.484375 10.941406 -0.515625 8.796875 -2.515625 C 6.648438 -4.515625 5.578125 -7.457031 5.578125 -11.34375 L 5.578125 -24.890625 L 0.484375 -24.890625 L 0.484375 -32.25 L 5.578125 -32.25 L 5.578125 -40.265625 L 15.140625 -40.265625 L 15.140625 -32.25 L 23.359375 -32.25 L 23.359375 -24.890625 L 15.140625 -24.890625 L 15.140625 -11.46875 C 15.140625 -10.070312 15.492188 -8.992188 16.203125 -8.234375 C 16.921875 -7.484375 17.9375 -7.109375 19.25 -7.109375 C 20.757812 -7.109375 22.046875 -7.519531 23.109375 -8.34375 Z M 25.6875 -1.59375 "
                />
              </g>
            </g>
          </g>
          <g fill="white" fill-opacity="1">
            <g transform="translate(159.836225, 59.447747)">
              <g>
                <path
                  d="M 4.40625 -45.484375 L 13.96875 -45.484375 L 13.96875 0 L 4.40625 0 Z M 4.40625 -45.484375 "
                />
              </g>
            </g>
          </g>
          <g fill="white" fill-opacity="1">
            <g transform="translate(178.285758, 59.447747)">
              <g>
                <path
                  d="M 37.140625 -32.984375 L 22.25 2.015625 C 20.738281 5.816406 18.867188 8.492188 16.640625 10.046875 C 14.410156 11.597656 11.722656 12.375 8.578125 12.375 C 6.859375 12.375 5.160156 12.109375 3.484375 11.578125 C 1.816406 11.046875 0.453125 10.3125 -0.609375 9.375 L 2.875 2.578125 C 3.613281 3.234375 4.460938 3.742188 5.421875 4.109375 C 6.378906 4.472656 7.332031 4.65625 8.28125 4.65625 C 9.582031 4.65625 10.640625 4.335938 11.453125 3.703125 C 12.273438 3.078125 13.015625 2.023438 13.671875 0.546875 L 13.796875 0.25 L -0.484375 -32.984375 L 9.375 -32.984375 L 18.640625 -10.609375 L 27.953125 -32.984375 Z M 37.140625 -32.984375 "
                />
              </g>
            </g>
          </g>
          <g fill="white" fill-opacity="1">
            <g transform="translate(2.96731, 62.665187)">
              <g>
                <path
                  d="M 26.921875 -9.28125 C 29.429688 -9.28125 31.476562 -9.796875 33.0625 -10.828125 C 34.65625 -11.859375 35.734375 -13.320312 36.296875 -15.21875 L 47.171875 -15.21875 C 46.773438 -10.976562 44.945312 -7.460938 41.6875 -4.671875 C 38.425781 -1.890625 34.34375 -0.273438 29.4375 0.171875 L 29.4375 7.28125 L 24.078125 7.28125 L 24.078125 0.171875 C 17.890625 -0.390625 13.09375 -2.648438 9.6875 -6.609375 C 6.289062 -10.566406 4.59375 -15.554688 4.59375 -21.578125 C 4.59375 -27.597656 6.289062 -32.585938 9.6875 -36.546875 C 13.09375 -40.503906 17.890625 -42.757812 24.078125 -43.3125 L 24.078125 -50.421875 L 29.4375 -50.421875 L 29.4375 -43.3125 C 34.34375 -42.875 38.425781 -41.257812 41.6875 -38.46875 C 44.945312 -35.675781 46.773438 -32.164062 47.171875 -27.9375 L 36.296875 -27.9375 C 35.015625 -31.894531 31.890625 -33.875 26.921875 -33.875 C 23.472656 -33.875 20.742188 -32.785156 18.734375 -30.609375 C 16.722656 -28.429688 15.71875 -25.421875 15.71875 -21.578125 C 15.71875 -17.734375 16.722656 -14.722656 18.734375 -12.546875 C 20.742188 -10.367188 23.472656 -9.28125 26.921875 -9.28125 Z M 26.921875 -9.28125 "
                />
              </g>
            </g>
          </g>
        </svg>

        <!-- <img
         
          src="@/assets/logo_small.svg"
          alt="Cently Logo"
          class="h-[50px] w-auto fill-white"
        /> -->
        <!-- <img
          v-else
          src="@/assets/logo.svg"
          alt="Cently Logo"
          class="h-[50px] w-auto fill-white"
        /> -->
      </div>
      <nav>
        <router-link
          to="/auth/dashboard"
          class="block py-2 px-4 hover:bg-gray-700 flex items-center"
          :class="{ 'bg-gray-700': $route.path === '/auth/dashboard' }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
          <span :class="{ 'hidden md:inline': isMobile }" class="ml-2"
            >Home</span
          >
        </router-link>
        <router-link
          to="/auth/insights"
          class="flex items-center px-4 py-2 hover:bg-gray-700"
          :class="{ 'bg-gray-700': $route.path === '/auth/insights' }"
        >
          <svg
            class="w-5 h-5 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            ></path>
          </svg>
          <span :class="{ 'hidden md:inline': isMobile }" class="ml-2"
            >Insights</span
          >
        </router-link>
        <router-link
          to="/auth/academy"
          class="block py-2 px-4 hover:bg-gray-700 flex items-center"
          :class="{ 'bg-gray-700': $route.path === '/auth/academy' }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
            />
          </svg>
          <span :class="{ 'hidden md:inline': isMobile }" class="ml-2"
            >Academy</span
          >
        </router-link>
      </nav>

      <!-- Add User menu toggle and menu here -->
      <div class="mt-auto p-4">
        <UserMenu
          :user="user"
          :user-plan="userPlan"
          :is-upgrading="isUpgrading"
          :is-collapsed="isMobile"
          @open-settings="openSettings"
          @logout="logout"
          @upgrade-to-premium="upgradeToPremium"
        />
      </div>
    </aside>

    <!-- Main content area -->
    <div :class="contentClasses">
      <!-- Page Content -->
      <main class="h-full overflow-y-auto">
        <div :class="mainContentClasses">
          <router-view
            :preferred-currency="preferredCurrency"
            @upgrade-to-premium="upgradeToPremium"
          ></router-view>
        </div>
      </main>

      <!-- Settings Modal -->
      <SettingsModal
        :show="showSettingsModal"
        @close="closeSettingsModal"
        @settings-updated="handleSettingsUpdated"
      />

      <!-- Overlay Loader -->
      <div v-if="isUpgrading" class="overlay-loader">
        <div class="loader"></div>
        <p>Loading...</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, computed, onMounted, onUnmounted } from "vue";
  import { auth, db } from "../firebase";
  import { signOut } from "firebase/auth";
  import { collection, addDoc, onSnapshot } from "firebase/firestore";
  import SettingsModal from "./SettingsModal.vue";
  import { getCustomClaimRole } from "../firebase";
  import UserMenu from "./UserMenu.vue";
  import { doc, getDoc } from "firebase/firestore";

  export default {
    name: "AuthenticatedLayout",
    components: {
      SettingsModal,
      UserMenu,
    },
    setup() {
      const isMobile = ref(false);

      const checkMobile = () => {
        isMobile.value = window.innerWidth < 768;
      };

      onMounted(() => {
        checkMobile();
        window.addEventListener("resize", checkMobile);
      });

      onUnmounted(() => {
        window.removeEventListener("resize", checkMobile);
      });

      const sidebarClasses = computed(() => ({
        "w-64": !isMobile.value,
        "w-16": isMobile.value,
        "bg-gray-800 text-white h-screen flex flex-col transition-all duration-300 ease-in-out fixed top-0 left-0 z-10": true,
      }));

      const contentClasses = computed(() => ({
        "ml-64": !isMobile.value,
        "ml-16": isMobile.value,
        "flex-1 flex flex-col min-h-screen transition-all duration-300 ease-in-out bg-gray-100": true,
      }));

      return {
        isMobile,
        sidebarClasses,
        contentClasses,
      };
    },
    data() {
      return {
        showSettingsModal: false,
        user: auth.currentUser,
        preferredCurrency: "USD",
        isUpgrading: false,
        userPlan: null,
      };
    },
    async created() {
      await this.fetchUserPreferences();
    },
    computed: {
      mainContentClasses() {
        const defaultClasses = "mx-auto py-6 px-4 sm:px-6 lg:px-8";
        const fullWidthRoutes = ["Academy", "Insights"];

        return fullWidthRoutes.includes(this.$route.name) ? "" : defaultClasses;
      },
    },
    async mounted() {
      try {
        const role = await getCustomClaimRole();
        this.userPlan = role === "premium" ? "Premium" : "Basic";
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    },
    methods: {
      async logout() {
        try {
          await signOut(auth);
          console.log("User logged out successfully");
          if (this.$route.path !== "/login") {
            this.$router.push("/login");
          }
        } catch (error) {
          console.error("Logout error:", error);
          alert("Logout failed. Please try again.");
        }
      },
      openSettings() {
        this.showSettingsModal = true;
      },
      closeSettingsModal() {
        this.showSettingsModal = false;
      },
      handleSettingsUpdated() {
        this.user = auth.currentUser;
        this.fetchUserPreferences();
      },
      async upgradeToPremium() {
        if (this.isUpgrading) return;

        this.isUpgrading = true;

        try {
          const checkoutSessionsRef = collection(
            db,
            "customers",
            this.user.uid,
            "checkout_sessions"
          );
          const docRef = await addDoc(checkoutSessionsRef, {
            price: "price_1Pz0q2J30MDq8VpWSXPaD3Lt",
            success_url: `${window.location.origin}/auth/dashboard?upgrade=success`,
            cancel_url: `${window.location.origin}/auth/dashboard?upgrade=cancelled`,
          });

          const unsubscribe = onSnapshot(docRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
              alert(`An error occurred: ${error.message}`);
              this.isUpgrading = false;
              unsubscribe();
            }
            if (url) {
              // Log the SUBSCRIBE_PAID event to Google Analytics
              // if (analytics) {
              //   logEvent(analytics, "conversion_event_subscribe_paid");
              // }
              window.location.assign(url);
              unsubscribe();
            }
          });
        } catch (error) {
          console.error("Error creating checkout session:", error);
          alert("Failed to initiate upgrade process. Please try again.");
          this.isUpgrading = false;
        }
      },
      async fetchUserPreferences() {
        const userDoc = await getDoc(doc(db, "users", this.user.uid));
        if (userDoc.exists()) {
          this.preferredCurrency = userDoc.data().preferredCurrency || "USD";
        }
      },
    },
  };
</script>

<style scoped>
  .overlay-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .overlay-loader p {
    color: white;
    margin-top: 20px;
    font-size: 18px;
  }

  .authenticated-layout {
    display: flex;
    min-height: 100vh;
  }

  .filter.invert {
    filter: invert(1);
  }
</style>
