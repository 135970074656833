<template>
  <div>
    <header class="bg-white shadow-sm">
      <div
        class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center"
      >
        <router-link to="/" class="flex items-center">
          <img
            src="@/assets/logo.svg"
            alt="Cently Logo"
            class="h-[50px] w-auto"
          />
        </router-link>
      </div>
    </header>

    <div class="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
      <h2 class="text-2xl font-bold mb-6 text-center">Reset Password</h2>
      <form @submit.prevent="resetPassword" class="space-y-4">
        <input
          v-model="email"
          type="email"
          placeholder="Email"
          required
          class="w-full px-3 py-2 border rounded-md"
        />
        <button
          type="submit"
          class="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
          :disabled="isLoading"
        >
          {{ isLoading ? "Sending..." : "Reset Password" }}
        </button>
      </form>
      <p v-if="message" class="mt-4 text-center" :class="messageClass">
        {{ message }}
      </p>
      <p class="mt-4 text-center">
        Remember your password?
        <router-link to="/login" class="text-blue-500 hover:underline"
          >Login</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
  import { auth } from "@/firebase";
  import { sendPasswordResetEmail } from "firebase/auth";

  export default {
    name: "ForgotPassword",
    data() {
      return {
        email: "",
        isLoading: false,
        message: "",
        messageClass: "",
      };
    },
    methods: {
      async resetPassword() {
        this.isLoading = true;
        this.message = "";
        this.messageClass = "";

        try {
          await sendPasswordResetEmail(auth, this.email);
          this.message = "Password reset email sent. Please check your inbox.";
          this.messageClass = "text-green-600";
          this.email = "";
        } catch (error) {
          console.error("Password reset error:", error);
          this.message = error.message;
          this.messageClass = "text-red-600";
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>
