<template>
  <div>
    <!-- Header section -->
    <header class="bg-white shadow-sm">
      <div
        class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center"
      >
        <router-link to="/" class="flex items-center">
          <img
            src="@/assets/logo.svg"
            alt="Cently Logo"
            class="h-[50px] w-auto"
          />
        </router-link>
        <nav>
          <router-link
            to="/login"
            class="text-gray-600 hover:text-gray-900 ml-4"
            >Login</router-link
          >
          <router-link
            to="/signup"
            class="bg-blue-600 text-white px-4 py-2 rounded-md ml-4 hover:bg-blue-700 transition duration-5"
            >Sign Up</router-link
          >
        </nav>
      </div>
    </header>

    <!-- Signup form -->
    <div class="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
      <h2 class="text-2xl font-bold mb-6 text-center">Sign Up</h2>
      <form @submit.prevent="signup" class="space-y-4">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700"
            >Email</label
          >
          <input
            v-model="email"
            id="email"
            type="email"
            placeholder="Email"
            required
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700"
            >Password</label
          >
          <input
            v-model="password"
            id="password"
            type="password"
            placeholder="Password"
            required
            @input="checkPasswordStrength"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label
            for="confirmPassword"
            class="block text-sm font-medium text-gray-700"
            >Confirm Password</label
          >
          <input
            v-model="confirmPassword"
            id="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            required
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div class="password-strength">
          <div
            class="strength-bar"
            :style="{
              width: `${passwordStrength}%`,
              backgroundColor: passwordStrengthColor,
            }"
          ></div>
          <span
            v-if="passwordStrength"
            class="strength-text"
            :style="{ color: passwordStrengthColor }"
            >{{ passwordStrengthText }}</span
          >
        </div>
        <button
          type="submit"
          :disabled="isLoading || !isFormValid"
          class="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {{ isLoading ? "Signing up..." : "Sign Up" }}
        </button>
      </form>
      <div class="mt-4">
        <button
          @click="signupWithGoogle"
          :disabled="isLoading"
          class="w-full mt-4 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {{ isLoading ? "Processing..." : "Sign Up with Google" }}
        </button>
      </div>
      <p v-if="errorMessage" class="mt-4 text-red-500 text-center">
        {{ errorMessage }}
      </p>
      <p class="mt-4 text-center">
        Already have an account?
        <router-link to="/login" class="text-blue-500 hover:underline"
          >Login</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
  import { auth, googleProvider } from "@/firebase";
  import {
    createUserWithEmailAndPassword,
    signInWithPopup,
  } from "firebase/auth";

  export default {
    name: "Signup",
    data() {
      return {
        email: "",
        password: "",
        confirmPassword: "",
        isLoading: false,
        errorMessage: "",
        passwordStrength: 0,
      };
    },
    computed: {
      isFormValid() {
        return (
          this.email &&
          this.password &&
          this.confirmPassword &&
          this.password === this.confirmPassword &&
          this.passwordStrength >= 60
        );
      },
      passwordStrengthColor() {
        if (this.passwordStrength < 30) return "#ff4d4d";
        if (this.passwordStrength < 60) return "#ffa500";
        return "#4caf50";
      },
      passwordStrengthText() {
        if (this.passwordStrength < 30) return "Weak";
        if (this.passwordStrength < 60) return "Medium";
        return "Strong";
      },
    },
    methods: {
      checkPasswordStrength() {
        const pwd = this.password;
        let strength = 0;
        if (pwd.length >= 8) strength += 20;
        if (pwd.match(/[a-z]+/)) strength += 20;
        if (pwd.match(/[A-Z]+/)) strength += 20;
        if (pwd.match(/[0-9]+/)) strength += 20;
        if (pwd.match(/[$@#&!]+/)) strength += 20;
        this.passwordStrength = strength;
      },
      async signup() {
        if (!this.isFormValid) return;
        this.isLoading = true;
        this.errorMessage = "";
        try {
          await createUserWithEmailAndPassword(auth, this.email, this.password);
          this.$router.push("/auth/dashboard");
        } catch (error) {
          console.error("Signup error:", error);
          this.errorMessage = this.getErrorMessage(error.code);
        } finally {
          this.isLoading = false;
        }
      },
      async signupWithGoogle() {
        this.isLoading = true;
        this.errorMessage = "";
        try {
          await signInWithPopup(auth, googleProvider);
          this.$router.push("/auth/dashboard");
        } catch (error) {
          console.error("Google signup error:", error);
          this.errorMessage = this.getErrorMessage(error.code);
        } finally {
          this.isLoading = false;
        }
      },
      getErrorMessage(errorCode) {
        switch (errorCode) {
          case "auth/email-already-in-use":
            return "This email is already in use";
          case "auth/invalid-email":
            return "Invalid email address";
          case "auth/weak-password":
            return "Password is too weak";
          default:
            return "An error occurred. Please try again";
        }
      },
    },
  };
</script>

<style scoped>
  .password-strength {
    margin-top: 0.5rem;
  }

  .strength-bar {
    height: 5px;
    transition: width 0.3s, background-color 0.3s;
  }

  .strength-text {
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
</style>
