import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAKYABvoFHXo7BAJN45CIDShxCtEKYuPNI",
    authDomain: "budget-buddy-1af23.firebaseapp.com",
    projectId: "budget-buddy-1af23",
    storageBucket: "budget-buddy-1af23.appspot.com",
    messagingSenderId: "346148007136",
    appId: "1:346148007136:web:4d1a37c11c98e47ef1f485",
    measurementId: "G-TTRYR4VKZ6"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export { auth, db, googleProvider, analytics };

export const getCustomClaimRole = async () => {
    try {
        await auth.currentUser.getIdToken(true);
        const decodedToken = await auth.currentUser.getIdTokenResult(true);
        return decodedToken.claims.stripeRole || "basic";
    } catch (error) {
        console.error("Error getting custom claim role:", error);
        return "basic";
    }
};