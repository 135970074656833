<template>
  <div class="bg-white overflow-hidden shadow-md rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Financial Health Score
      </h3>
      <div class="mt-2 flex items-center">
        <div class="text-3xl font-bold text-indigo-600">
          {{ displayScore }}
        </div>
        <div class="ml-2 text-sm text-gray-500">/ 100</div>
      </div>
      <p class="mt-1 text-sm text-gray-500">{{ message }}</p>
      <div class="mt-3 text-sm text-gray-600">
        <p class="mt-2">
          For detailed insights,
          <router-link
            to="/auth/insights"
            class="text-indigo-600 hover:text-indigo-800"
            >visit the Insights page</router-link
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FinancialHealthScore",
    props: {
      totalIncome: {
        type: [Number, String],
        required: true,
      },
      totalSavings: {
        type: [Number, String],
        required: true,
      },
      totalExpenses: {
        type: [Number, String],
        required: true,
      },
      net: {
        type: [Number, String],
        required: true,
      },
    },
    data() {
      return {
        displayScore: 0,
      };
    },
    computed: {
      score() {
        const income = Number(this.totalIncome);
        const savings = Number(this.totalSavings);
        const expenses = Number(this.totalExpenses);
        const net = Number(this.net);

        if (income === 0) return 0;

        let score = 0;

        // Factor 1: Savings rate (weight: 40%)
        const savingsRate = savings / income;
        score += Math.min(savingsRate * 100, 40);

        // Factor 2: Expense-to-income ratio (weight: 30%)
        const expenseRatio = expenses / income;
        score += Math.max(0, 30 - expenseRatio * 30);

        // Factor 3: Net positive (weight: 30%)
        if (net > 0) {
          score += 30;
        } else {
          score += Math.max(0, 30 + (net / income) * 30);
        }

        return Math.round(score || 0);
      },
      message() {
        if (this.totalIncome + this.totalSavings + this.totalExpenses === 0) {
          return "Please enter your income, savings, and expenses to calculate your financial health score.";
        }
        if (this.score >= 80) {
          return "Excellent! Your finances are in great shape.";
        } else if (this.score >= 60) {
          return "Good job! There's room for improvement, but you're on the right track.";
        } else if (this.score >= 40) {
          return "You're making progress, but consider ways to improve your savings and reduce expenses.";
        } else {
          return "Your financial health needs attention. Focus on increasing savings and reducing expenses.";
        }
      },
    },
    watch: {
      score: {
        immediate: true,
        handler(newScore) {
          this.animateScore(newScore);
        },
      },
    },
    methods: {
      animateScore(newScore) {
        const duration = 1000; // Animation duration in milliseconds
        const start = this.displayScore;
        const end = newScore;
        const range = end - start;
        const startTime = performance.now();

        const animate = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          if (elapsedTime < duration) {
            const progress = elapsedTime / duration;
            this.displayScore = Math.round(
              start + range * this.easeOutQuad(progress)
            );
            requestAnimationFrame(animate);
          } else {
            this.displayScore = end;
          }
        };

        requestAnimationFrame(animate);
      },
      easeOutQuad(t) {
        return t * (2 - t);
      },
    },
  };
</script>
