import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth, analytics } from "../firebase";
import { logEvent } from 'firebase/analytics';
import LandingPage from '@/components/LandingPage.vue'
import Login from '@/components/Login.vue'
import Signup from '@/components/Signup.vue'
import AuthenticatedLayout from '@/components/AuthenticatedLayout.vue'
import Dashboard from '@/components/Dashboard.vue'
import Academy from '@/components/Academy.vue'
import ForgotPassword from '@/components/ForgotPassword.vue'
import PrivacyPolicy from '@/components/policies/PrivacyPolicy.vue'
import TermsOfService from '@/components/policies/TermsOfService.vue'
import RefundPolicy from '@/components/policies/RefundPolicy.vue'; // Add this line

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Landing', component: LandingPage, meta: { title: 'Welcome!' } },
    { path: '/login', name: 'Login', component: Login, meta: { title: 'Login' } },
    { path: '/signup', name: 'Signup', component: Signup, meta: { title: 'Signup' } },
    {
        path: '/auth',
        component: AuthenticatedLayout,
        meta: { requiresAuth: true },
        children: [
            { path: '', redirect: { name: 'Dashboard' } },
            { path: 'dashboard', name: 'Dashboard', component: Dashboard, meta: { title: 'Dashboard' } },
            {
                path: 'insights',
                name: 'Insights',
                component: () => import('../components/Insights/index.vue'),
                meta: { requiresAuth: true, title: 'Insights' }
            },
            {
                path: 'academy',
                name: 'Academy',
                component: Academy,
                meta: { title: 'Academy' }
            }
        ]
    },
    { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword, meta: { title: 'Forgot Password' } },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: { title: 'Privacy Policy' }
    },
    {
        path: '/terms-of-service',
        name: 'TermsOfService',
        component: TermsOfService,
        meta: { title: 'Terms of Service' }
    },
    {
        path: '/refund-policy',
        name: 'RefundPolicy',
        component: RefundPolicy,
        meta: { title: 'Refund Policy' }
    }, // Add this route
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const currentUser = auth.currentUser
    const title = to.meta.title || ""

    document.title = title ? `Cently | ${title}` : 'Cently'

    if (requiresAuth && !currentUser) {
        next('/login')
    } else if (to.path === '/login' && currentUser) {
        next('/auth/dashboard')
    } else {
        next()
    }
})

// Track route changes and log page views in Firebase Analytics
router.afterEach((to) => {
    if (analytics) {
        logEvent(analytics, 'page_view', { page_path: to.path });
    }
});

export default router