<template>
  <div class="refund-policy">
    <h1>Refund and Return Policy</h1>
    <p class="last-updated">Last updated: {{ lastUpdated }}</p>
    <section v-for="(section, index) in sections" :key="index">
      <h2>{{ section.title }}</h2>
      <div v-html="section.content"></div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "RefundPolicy",
    data() {
      return {
        lastUpdated: "15 September 2024",
        sections: [
          {
            title: "1. Introduction",
            content: `
            <p>This Refund and Return Policy applies to Cently's premium subscription service. As Cently primarily offers digital services, our refund policy is designed to be fair to both our users and our business, while complying with Dutch consumer protection laws.</p>
          `,
          },
          {
            title: "2. Right of Withdrawal",
            content: `
            <p>In accordance with Dutch law, you have the right to withdraw from your premium subscription within 14 days without giving any reason. This withdrawal period will expire after 14 days from the day of the conclusion of the contract (i.e., the day you subscribed to our premium service).</p>
            <p>To exercise the right of withdrawal, you must inform us of your decision to withdraw from this contract by an unequivocal statement (e.g., a letter sent by post or email). You may use the model withdrawal form provided below, but it is not obligatory.</p>
          `,
          },
          {
            title: "3. Effects of Withdrawal",
            content: `
            <p>If you withdraw from this contract within the 14-day period, we will reimburse all payments received from you, without undue delay and in any event not later than 14 days from the day on which we are informed about your decision to withdraw from this contract.</p>
            <p>We will carry out such reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of such reimbursement.</p>
          `,
          },
          {
            title:
              "4. Commencement of Services Before the End of the Withdrawal Period",
            content: `
            <p>If you have requested to begin the performance of services during the withdrawal period, you shall pay us an amount which is in proportion to what has been provided until you have communicated to us your withdrawal from this contract, in comparison with the full coverage of the contract.</p>
          `,
          },
          {
            title: "5. Exceptions to the Right of Withdrawal",
            content: `
            <p>The right of withdrawal may not apply to certain digital content or services if you have expressly consented to the beginning of the performance before the end of the withdrawal period and acknowledged that you thereby lose your right of withdrawal.</p>
          `,
          },
          {
            title: "6. Refunds After the Withdrawal Period",
            content: `
            <p>After the 14-day withdrawal period, refunds may be issued on a case-by-case basis, at the discretion of Cently. Factors considered may include the amount of time since purchase, the reason for the refund request, and the extent of service usage.</p>
          `,
          },
          {
            title: "7. How to Request a Refund",
            content: `
            <p>To request a refund, please contact our customer support team at mail4jakes@gmail.com. Please provide your account details and the reason for your refund request.</p>
          `,
          },
          {
            title: "8. Processing of Refunds",
            content: `
            <p>Approved refunds will be processed within 14 days of the approval. Refunds will be issued using the original method of payment used for the purchase.</p>
          `,
          },
          {
            title: "9. Changes to This Policy",
            content: `
            <p>We reserve the right to modify this Refund and Return Policy at any time. Changes will be effective immediately upon posting on our website. Your continued use of our services after any changes to this policy constitutes your acceptance of such changes.</p>
          `,
          },
          {
            title: "10. Contact Us",
            content: `
            <p>If you have any questions about this Refund and Return Policy, please contact us at mail4jakes@gmail.com.</p>
          `,
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .refund-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .last-updated {
    text-align: center;
    margin-bottom: 30px;
    font-style: italic;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 10px;
  }
</style>
