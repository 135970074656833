<template>
  <div class="bg-white overflow-hidden shadow rounded-lg p-6">
    <div class="space-y-4 mt-4">
      <div v-for="(item, key) in sliders" :key="key">
        <label :for="key" class="block text-sm font-medium text-gray-700">
          {{ item.label }}: {{ formatCurrency(item.value) }}
        </label>
        <input
          :id="key"
          type="range"
          v-model="item.value"
          :min="item.min"
          :max="item.max"
          :step="item.step"
          class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
      </div>
    </div>
    <div class="mt-6">
      <FinancialHealthScore
        :totalIncome="sliders.income.value"
        :totalSavings="sliders.savings.value"
        :totalExpenses="sliders.expenses.value"
        :net="net"
        class="shadow-none"
      />
    </div>
  </div>
</template>

<script>
  import FinancialHealthScore from "./FinancialHealthScore.vue";

  export default {
    name: "InteractiveFinancialHealthScore",
    components: {
      FinancialHealthScore,
    },
    data() {
      return {
        sliders: {
          income: {
            label: "Monthly Income",
            value: 5000,
            min: 0,
            max: 20000,
            step: 100,
          },
          savings: {
            label: "Monthly Savings",
            value: 1000,
            min: 0,
            max: 10000,
            step: 100,
          },
          expenses: {
            label: "Monthly Expenses",
            value: 3000,
            min: 0,
            max: 15000,
            step: 100,
          },
        },
      };
    },
    computed: {
      net() {
        return (
          this.sliders.income.value -
          this.sliders.savings.value -
          this.sliders.expenses.value
        );
      },
    },
    methods: {
      formatCurrency(value) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value);
      },
    },
  };
</script>
