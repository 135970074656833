<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <h2 class="text-2xl font-bold mb-4">Settings</h2>
      <div class="mb-4">
        <label for="displayName" class="block mb-2">Display Name</label>
        <input
          id="displayName"
          v-model="displayName"
          type="text"
          class="w-full px-3 py-2 border rounded"
        />
      </div>
      <div class="mb-4">
        <label for="currency" class="block mb-2">Preferred Currency</label>
        <select
          id="currency"
          v-model="preferredCurrency"
          class="w-full px-3 py-2 border rounded"
        >
          <option value="USD">USD - US Dollar</option>
          <option value="EUR">EUR - Euro</option>
          <option value="GBP">GBP - British Pound</option>
          <option value="JPY">JPY - Japanese Yen</option>
          <option value="CAD">CAD - Canadian Dollar</option>
        </select>
      </div>
      <!-- Add change password section -->
      <div class="mb-4">
        <h3 class="text-lg font-semibold mb-2">Change Password</h3>
        <div class="mb-2">
          <label for="currentPassword" class="block mb-1"
            >Current Password</label
          >
          <input
            id="currentPassword"
            v-model="currentPassword"
            type="password"
            class="w-full px-3 py-2 border rounded"
          />
        </div>
        <div class="mb-2">
          <label for="newPassword" class="block mb-1">New Password</label>
          <input
            id="newPassword"
            v-model="newPassword"
            type="password"
            class="w-full px-3 py-2 border rounded"
          />
        </div>
        <div class="mb-2">
          <label for="confirmPassword" class="block mb-1"
            >Confirm New Password</label
          >
          <input
            id="confirmPassword"
            v-model="confirmPassword"
            type="password"
            class="w-full px-3 py-2 border rounded"
          />
        </div>
        <button
          @click="changePassword"
          class="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          :disabled="!canChangePassword"
        >
          Change Password
        </button>
      </div>
      <div class="flex justify-end">
        <button
          @click="saveSettings"
          class="bg-blue-500 text-white px-4 py-2 rounded mr-2"
        >
          Save
        </button>
        <button
          @click="$emit('close')"
          class="bg-gray-300 text-gray-800 px-4 py-2 rounded"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { auth, db } from "../firebase";
  import {
    updateProfile,
    updatePassword,
    reauthenticateWithCredential,
    EmailAuthProvider,
  } from "firebase/auth";
  import { doc, updateDoc, getDoc, setDoc } from "firebase/firestore";

  export default {
    name: "SettingsModal",
    props: {
      show: Boolean,
    },
    data() {
      return {
        displayName: auth.currentUser?.displayName || "",
        preferredCurrency: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      };
    },
    computed: {
      canChangePassword() {
        return (
          this.currentPassword &&
          this.newPassword &&
          this.confirmPassword &&
          this.newPassword === this.confirmPassword
        );
      },
    },
    async created() {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (userDoc.exists()) {
        this.preferredCurrency = userDoc.data().preferredCurrency || "USD";
      } else {
        this.preferredCurrency = "USD";
      }
    },
    methods: {
      async saveSettings() {
        try {
          await updateProfile(auth.currentUser, {
            displayName: this.displayName,
          });

          const userDocRef = doc(db, "users", auth.currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            await updateDoc(userDocRef, {
              preferredCurrency: this.preferredCurrency,
            });
          } else {
            await setDoc(userDocRef, {
              preferredCurrency: this.preferredCurrency,
            });
          }

          this.$emit("settings-updated");
          this.$emit("close");
        } catch (error) {
          console.error("Error updating settings:", error);
          alert("Failed to update settings. Please try again.");
        }
      },
      async changePassword() {
        if (!this.canChangePassword) return;

        try {
          const user = auth.currentUser;
          const credential = EmailAuthProvider.credential(
            user.email,
            this.currentPassword
          );

          // Reauthenticate the user
          await reauthenticateWithCredential(user, credential);

          // Update the password
          await updatePassword(user, this.newPassword);

          alert("Password changed successfully!");
          this.currentPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
        } catch (error) {
          console.error("Error changing password:", error);
          alert(
            "Failed to change password. Please check your current password and try again."
          );
        }
      },
    },
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
  }
</style>
