<template>
  <div class="landing-page">
    <header
      class="container mx-auto px-4 py-6 flex justify-between items-center"
    >
      <img src="@/assets/logo.svg" alt="Cently Logo" class="h-[50px] w-auto" />
      <nav>
        <router-link
          to="/auth"
          class="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition duration-300 font-semibold"
          >Get Started</router-link
        >
      </nav>
    </header>

    <!-- Banner Section with blur effect -->
    <div class="relative overflow-hidden bg-gray-50">
      <div class="absolute inset-0 flex justify-end pr-4 md:pr-8 lg:pr-16">
        <img
          src="@/assets/banner_showcase.png"
          alt="Cently Budget Management"
          class="w-full md:w-3/4 lg:w-1/2 h-full object-cover object-right filter blur-sm lg:filter-none lg:blur-none"
        />
        <!-- Add dark overlay for mobile and medium devices -->
        <div class="absolute inset-0 bg-black opacity-80 lg:opacity-0"></div>
      </div>
      <div class="relative z-10 bg-black bg-opacity-0 py-16 lg:py-32">
        <div
          class="container mx-auto px-4 flex flex-col lg:flex-row items-center"
        >
          <div class="text-center lg:text-left w-full lg:w-1/2 mb-8 lg:mb-0">
            <h1
              class="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 lg:mb-6 text-white lg:text-black pr-4 md:pr-8 lg:pr-16"
            >
              Simplify Your Finances with Cently
            </h1>
            <p
              class="text-xl md:text-2xl lg:text-3xl mb-6 lg:mb-8 text-white lg:text-black"
            >
              Free, Easy-to-Use Budgeting
            </p>

            <p
              class="text-lg lg:text-xl mb-8 lg:mb-10 max-w-2xl mx-auto lg:mx-0 text-white lg:text-black pr-4 md:pr-8 lg:pr-16"
            >
              Take control of your money with our intuitive budgeting app. Track
              expenses, learn better money management, and gain valuable
              financial insights - all at no cost.
            </p>

            <router-link
              to="/auth"
              class="bg-blue-600 text-white px-6 py-3 lg:px-10 lg:py-4 rounded-md text-lg lg:text-xl font-semibold hover:bg-blue-700 transition duration-300 inline-flex items-center"
            >
              Start Your Financial Journey
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 lg:h-6 lg:w-6 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <main class="container mx-auto px-4 py-16">
      <div class="grid md:grid-cols-3 gap-8 mb-16">
        <div class="bg-white p-6 rounded-lg shadow-md">
          <div class="text-blue-600 mb-4">
            <img
              src="@/assets/dollar_sign.svg"
              alt="Dollar Sign"
              class="h-12 w-12"
            />
          </div>
          <h3 class="text-xl font-semibold mb-4 text-gray-800">
            Simple Expense Tracking
          </h3>
          <p class="text-gray-600">
            Effortlessly monitor your income, expenses, and savings in one place
            with our user-friendly interface. Stay on top of your finances with
            ease.
          </p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow-md">
          <div class="text-blue-600 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
          </div>
          <h3 class="text-xl font-semibold mb-4 text-gray-800">
            Secure & Private
          </h3>
          <p class="text-gray-600">
            Your financial data is encrypted and never shared. We prioritize
            your privacy and security, giving you peace of mind as you manage
            your money.
          </p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow-md">
          <div class="text-blue-600 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
              />
            </svg>
          </div>
          <h3 class="text-xl font-semibold mb-4 text-gray-800">
            Financial Education Hub (coming soon)
          </h3>
          <p class="text-gray-600">
            Access our Academy to learn about personal finance, budgeting
            techniques, and money-saving strategies.
          </p>
        </div>
      </div>

      <div class="text-center">
        <h2 class="text-3xl font-semibold mb-6 text-gray-800">
          Empower Your Financial Future
        </h2>
        <p class="text-lg mb-8 max-w-2xl mx-auto text-gray-600">
          Whether you're saving for a goal, paying off debt, or building wealth,
          Cently provides the tools and knowledge you need to succeed.
        </p>

        <router-link
          to="/auth"
          class="bg-blue-600 text-white px-8 py-3 rounded-md text-lg font-semibold hover:bg-blue-700 transition duration-300 inline-flex items-center"
        >
          Get Started for Free
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 ml-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </router-link>
      </div>
    </main>

    <!-- Add this section where you want the interactive tool to appear -->
    <section class="py-12 bg-gray-50">
      <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center">
          <h2 class="text-3xl font-semibold mb-6 text-gray-800">
            Try Our Financial Health Calculator
          </h2>
          <p class="mt-4 text-lg text-gray-500 max-w-2xl mx-auto">
            See how your income, savings, and expenses affect your financial
            health score.
          </p>
        </div>
        <div class="mt-8">
          <InteractiveFinancialHealthScore />
        </div>
      </div>
    </section>

    <LegalFooter />
  </div>
</template>

<script>
  import LegalFooter from "./policies/LegalFooter.vue";
  import InteractiveFinancialHealthScore from "./InteractiveFinancialHealthScore.vue";

  export default {
    name: "LandingPage",
    computed: {
      currentYear() {
        return new Date().getFullYear();
      },
    },
    components: {
      LegalFooter,
      InteractiveFinancialHealthScore,
    },
  };
</script>
