<template>
  <div class="terms-of-service">
    <h1>Terms of Service</h1>
    <section v-for="(section, index) in sections" :key="index">
      <h2>{{ section.title }}</h2>
      <div v-html="section.content"></div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "TermsOfService",
    data() {
      return {
        sections: [
          {
            title: "1. Introduction",
            content: `
            <p>Welcome to Cently. By using our services, you agree to these Terms of Service. Please read them carefully.</p>
          `,
          },
          {
            title: "2. Definitions",
            content: `
            <p>"We", "us", "our" refers to Cently.</p>
            <p>"Service" refers to the Cently budgeting and personal finance management application.</p>
            <p>"User", "you", "your" refers to any person or entity using our Service.</p>
          `,
          },
          {
            title: "3. Acceptance of Terms",
            content: `
            <p>By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not use our Service.</p>
          `,
          },
          {
            title: "4. Changes to Terms",
            content: `
            <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page and updating the "Last updated" date.</p>
          `,
          },
          {
            title: "5. Description of Service",
            content: `
            <p>Cently is a budgeting and personal finance management application that allows users to track income, expenses, and savings. The Service includes features such as expense categorization, financial insights, and debit order tracking. Premium users have access to additional features like detailed analytics and personalized financial recommendations.</p>
          `,
          },
          {
            title: "6. User Obligations",
            content: `
            <p>You agree to use the Service only for lawful purposes and in accordance with these Terms.</p>
            <p>You are responsible for maintaining the confidentiality of your account and password.</p>
            <p>You agree to provide accurate and up-to-date information when using the Service.</p>
          `,
          },
          {
            title: "7. Intellectual Property Rights",
            content: `
            <p>The Service and its original content, features, and functionality are owned by Cently and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
          `,
          },
          {
            title: "8. Limitation of Liability",
            content: `
            <p>To the fullest extent permitted by applicable law, Cently shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues.</p>
            <p>Cently does not guarantee the accuracy of financial insights or recommendations provided through the Service.</p>
          `,
          },
          {
            title: "9. Termination",
            content: `
            <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation.</p>
          `,
          },
          {
            title: "10. Governing Law",
            content: `
            <p>These Terms shall be governed and construed in accordance with the laws of the Netherlands, without regard to its conflict of law provisions.</p>
          `,
          },
          {
            title: "11. Dispute Resolution",
            content: `
            <p>Any disputes arising out of or relating to these Terms or the Service will be resolved through negotiation, mediation, or as a last resort, through the Dutch courts.</p>
          `,
          },
          {
            title: "12. Contact Information",
            content: `
            <p>For any questions about these Terms, please contact us at mail4jakes@gmail.com.</p>
          `,
          },
        ],
        lastUpdated: new Date().toISOString().split("T")[0],
      };
    },
  };
</script>

<style scoped>
  .terms-of-service {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }

  h1 {
    text-align: center;
    margin-bottom: 30px;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 10px;
  }
</style>
