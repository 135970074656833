<template>
  <footer class="bg-gray-100 py-6">
    <div class="container mx-auto px-4">
      <nav class="flex flex-wrap justify-center mb-4">
        <router-link
          v-for="link in links"
          :key="link.to"
          :to="link.to"
          class="text-gray-600 hover:text-gray-900 mx-3 my-1 text-sm"
        >
          {{ link.text }}
        </router-link>
      </nav>
      <p class="text-center text-gray-500 text-sm">
        © {{ currentYear }} Cently. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
  export default {
    data() {
      return {
        links: [
          { to: "/privacy-policy", text: "Privacy Policy" },
          { to: "/terms-of-service", text: "Terms of Service" },
          { to: "/refund-policy", text: "Refund Policy" }, // Add this line
        ],
      };
    },
    computed: {
      currentYear() {
        return new Date().getFullYear();
      },
    },
  };
</script>
