import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import { auth } from './firebase'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip, {
  disposeTimeout: 150,
  defaultBoundariesElement: document.body,
  defaultClass: 'custom-tooltip-theme',
  defaultTemplate: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
})

Vue.config.productionTip = false

let app

auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})

// If you're using a currency formatting plugin, make sure it's configured correctly
// For example:
// Vue.use(VueCurrencyFilter, {
//   symbol: '$',
//   thousandsSeparator: ',',
//   fractionCount: 2,
//   fractionSeparator: '.',
//   symbolPosition: 'front',
//   symbolSpacing: true
// })
